import type { LikeSettings } from "@motain/xpa-proto-files-web/lib/types/like_settings";
import { Size } from "@motain/xpa-proto-files-web/lib/types/like_settings";
import type { FC } from "react";
import { useState } from "react";
import { useAuthentication } from "@/context/Authentication";
import { isNullable } from "@/types/isNullable";
import { PROFILE_SIGN_UP_ROUTE } from "@/constants/routes.constant";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { useFollowClickHandler } from "@/xpa-components/follow-button/useFollowClickHandler";
import styles from "./FollowButton.module.scss";
import classNames from "classnames";
import type { ImageObject } from "@motain/xpa-proto-files-web/lib/types/image";
import { Button } from "@/components/button/Button";
import { ButtonLink } from "@/components/button/ButtonLink";
import { useRouter } from "next/router";
import { SearchParam } from "@/enums/search-param.enum";

type LikeSettingsProps = LikeSettings & { className?: string };

const favouriteIcon: ImageObject = {
  alt: "",
  darkModePath: "https://images.onefootball.com/cw/icons/heart-red-dark.svg",
  isPreloaded: false,
  path: "https://images.onefootball.com/cw/icons/heart-red-dark.svg",
  size: undefined,
};

export const FollowButton: FC<LikeSettingsProps> = (props) => {
  const {
    likeEndpoint,
    likedShapeIcon,
    unlikedShapeIcon,
    unlikeEndpoint,
    className,
    size,
  } = props;
  const { authState } = useAuthentication();
  const { clickHandler, isLikedState } = useFollowClickHandler(props);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();

  const urlSearchParams = new URLSearchParams({
    [SearchParam.Redirect]: router.asPath,
    [SearchParam.Origin]: "follow-button",
  });
  const redirectUrl = `${PROFILE_SIGN_UP_ROUTE}?${urlSearchParams.toString()}`;
  /**
   * The current implementation of the follow button accepts 2 possible states
   * (true-false // like-dislike // favourite-unfavourite)
   *
   * For this button we needed to create 3 possible states (like-dislike-favourite) since its not possible to implement
   * that flow without reworking the whole implementation we decided that we will only work with like-dislike,
   * and just display when the "favourite" option without any interactivity (the user will not be able to unfavourite nor
   * favourite from this component).
   */
  const isFavourite = isNullable(likeEndpoint) && isNullable(unlikeEndpoint);

  const Tag = authState.kind === "authenticated" ? Button : ButtonLink;

  const [altText, darkMode, message] = isLikedState
    ? [
        likedShapeIcon?.alt ?? "",
        likedShapeIcon?.darkModePath ?? "",
        unlikeEndpoint?.name ?? "",
      ]
    : [
        unlikedShapeIcon?.alt ?? "",
        unlikedShapeIcon?.darkModePath ?? "",
        likeEndpoint?.name ?? "",
      ];

  return (
    <Tag
      href={redirectUrl} //link only property
      disabled={isLoading || isFavourite} // button only property
      className={classNames(
        "enforced-dark",
        styles.container,
        "title-7-bold",
        className,
        isFavourite ? styles.favourite : "",
      )}
      variant={"action"}
      onClick={() => {
        setIsLoading(true);

        void clickHandler().then(() => {
          setIsLoading(false);
        });
      }}
    >
      {isFavourite && authState.kind === "authenticated" ? (
        <ImageWithFallback
          data-testid="favourite-icon"
          width={24}
          height={24}
          src={favouriteIcon.path}
          alt={favouriteIcon.alt}
          darkModeSrc={favouriteIcon.darkModePath}
        ></ImageWithFallback>
      ) : (
        <ImageWithFallback
          width={24}
          height={24}
          src={darkMode}
          alt={altText}
          darkModeSrc={darkMode}
        ></ImageWithFallback>
      )}

      {size === Size.Size_LARGE && <> {message}</>}
    </Tag>
  );
};
