import type { FC } from "react";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import styles from "./CustomBgElement.module.scss";
import { MEDIA_QUERY_MIN_WIDTH } from "@/constants/media-query.constant";
import classNames from "classnames";

const CUSTOM_BG_ELEMENT_URLS = {
  desktop: "https://images.onefootball.com/cw/bg/euros-desktop-v4.svg",
  tablet: "https://images.onefootball.com/cw/bg/euros-tablet-v3.svg",
  mobile: "https://images.onefootball.com/cw/bg/euros-mobile-v3.svg",
};

/**
 * Sometimes OF creates custom header backgrounds for specific competitions for a limited period of time
 * For this cases use this component to display the custom background
 */

export const CustomBgElement: FC = () => {
  return (
    <>
      <ImageWithFallback
        data-testid="custom-bg-element-desktop"
        isPreloaded
        alt=""
        className={classNames(styles.image, styles.desktop)}
        width={MEDIA_QUERY_MIN_WIDTH.desktop}
        height={300}
        src={CUSTOM_BG_ELEMENT_URLS.desktop}
      />
      <ImageWithFallback
        data-testid="custom-bg-element-tablet"
        isPreloaded
        alt=""
        className={classNames(styles.image, styles.tablet)}
        height={365}
        width={MEDIA_QUERY_MIN_WIDTH.tabletPortrait}
        src={CUSTOM_BG_ELEMENT_URLS.tablet}
      />
      <ImageWithFallback
        data-testid="custom-bg-element-mobile"
        className={classNames(styles.image, styles.mobile)}
        isPreloaded
        alt=""
        height={450}
        width={MEDIA_QUERY_MIN_WIDTH.mobile}
        src={CUSTOM_BG_ELEMENT_URLS.mobile}
      />
    </>
  );
};
