import type { FC } from "react";
import type { EntityTitleComponent } from "@motain/xpa-proto-files-web/lib/types/entity_title";
import { isNonNullable } from "@/types/isNonNullable";
import styles from "./EntityTitle.module.scss";
import classNames from "classnames";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { FollowButton } from "@/xpa-components/follow-button/FollowButton";
import { CustomBgElement } from "@/xpa-components/entity-title/CustomBgElement";
import { useRouter } from "next/router";

// For the competitions that require an exception code use the following constant to use the custom background component
export const COMPETITION_EXCEPTION_CODE = "20"; // 20 is the uefa-european-championship code
const extractLastNumbers = (url: string) => {
  const match = url.match(/\d{2,3}$/);
  return match ? match[0] : null;
};

export const EntityTitle: FC<EntityTitleComponent> = (props) => {
  const { imageObject, title, likeSettings } = props;
  const { asPath } = useRouter();

  const isExceptionCode =
    extractLastNumbers(asPath) === COMPETITION_EXCEPTION_CODE;

  return (
    <div
      className={classNames(
        "enforced-dark",
        styles.container,
        !isExceptionCode ? styles.gradient : styles.exceptionBgGradient,
      )}
    >
      <div className={styles.limitWrapper}>
        {isNonNullable(likeSettings) && (
          <FollowButton
            {...likeSettings}
            className={styles.followButton}
          ></FollowButton>
        )}
        <div className={styles.content}>
          {isNonNullable(imageObject) && (
            <ImageWithFallback
              width={120}
              height={120}
              src={imageObject.path}
              isPreloaded={imageObject.isPreloaded}
              alt={imageObject.alt}
              className={styles.logo}
            />
          )}
          {isNonNullable(title) && title !== "" && (
            <p className={classNames("title-1-bold-druk", styles.title)}>
              {title}
            </p>
          )}
        </div>
      </div>

      {isExceptionCode ? (
        <CustomBgElement />
      ) : (
        <ImageWithFallback
          src={"https://images.onefootball.com/cw/bg/zigzag-pattern-banner.svg"}
          alt=""
          fill
          isPreloaded={true}
          className={styles.image}
        ></ImageWithFallback>
      )}
    </div>
  );
};
