import type { FC } from "react";
import { useEffect, useRef } from "react";
import Link from "next/link";
import classNames from "classnames";
import { isInViewport } from "@/utils/isInViewport";
import styles from "./PageTabs.module.scss";
import type {
  Tab,
  TabsComponent,
} from "@motain/xpa-proto-files-web/lib/types/tabs";
import { isNonNullable } from "@/types/isNonNullable";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";

/**
 * Scrollable navigation list of tabs.
 */
export const PageTabs: FC<TabsComponent> = (props) => {
  return (
    <nav aria-label={props.title} className={styles.pageTabs}>
      <ul className={styles.list}>
        {props.items.map((tab) => (
          <li key={tab.name} className={styles.listItem}>
            <PageTab data={tab} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

const PageTab: FC<{ data: Tab }> = ({ data }) => {
  const ref = useRef<HTMLAnchorElement | null>(null);

  useEffect(scrollActiveTabIntoViewIfNotVisible, [data.isActive]);

  function scrollActiveTabIntoViewIfNotVisible() {
    const tab = ref.current;

    if (tab === null || !data.isActive) {
      return;
    }

    void isInViewport(tab, { threshold: 1 }).then((inViewport) => {
      if (!inViewport) {
        tab.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }
    });
  }

  return (
    <Link
      href={data.urlPath}
      className={classNames(styles.link, {
        [String(styles.linkActive)]: data.isActive,
      })}
    >
      {data.name}
      {isNonNullable(data.icon) && (
        <ImageWithFallback
          width={24}
          height={24}
          isPreloaded={data.icon.isPreloaded}
          src={data.icon.path}
          alt={data.icon.alt}
          darkModeSrc={data.icon.darkModePath}
        ></ImageWithFallback>
      )}
    </Link>
  );
};
