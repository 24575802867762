import type { LikeSettings } from "@motain/xpa-proto-files-web/lib/types/like_settings";
import { useAuthentication } from "@/context/Authentication";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import type { Link } from "@motain/xpa-proto-files-web/lib/types/link";
import { webExperienceFetch } from "@/utils/webExperienceFetch";
import { FETCH_METHOD } from "@/constants/fetch-method";
import { isValidHTTPStatusCode } from "@/utils/isValidHTTPStatusCode";
import { isNonNullable } from "@/types/isNonNullable";
import { useContext, useState } from "react";
import { SnackbarContext } from "@/components/snackbar/Snackbar.context";
import { SeverityType } from "@/components/snackbar/Snackbar";
import useTranslation from "next-translate/useTranslation";

const requestChange = async (
  endpoint: Link,
  isLiked: boolean,
): Promise<boolean> => {
  trackingService.sendXpaTracking(
    endpoint.trackingEvents,
    EventType.EVENT_CLICK,
  );

  try {
    const response = await webExperienceFetch(endpoint.urlPath, {
      method: isLiked ? FETCH_METHOD.DELETE : FETCH_METHOD.POST,
    });

    if (isValidHTTPStatusCode(response.status)) {
      return true;
    } else {
      return false;
    }
  } catch (_) {
    return false;
  }
};

export const useFollowClickHandler = (
  props: LikeSettings,
): { clickHandler: () => Promise<void>; isLikedState: boolean } => {
  const {
    isLiked,
    likeEndpoint,
    unlikeEndpoint,
    likeSnackbar,
    unlikeSnackbar,
  } = props;
  const { authState } = useAuthentication();
  const [isLikedState, setIsLikedState] = useState<boolean>(isLiked);
  const showNotification = useContext(SnackbarContext);
  const { t } = useTranslation("web-payments");
  const changeFollowStatus = async () => {
    if (authState.kind === "unauthenticated" && likeEndpoint?.trackingEvents) {
      trackingService.sendXpaTracking(
        likeEndpoint.trackingEvents,
        EventType.EVENT_CLICK,
      );
    }

    if (
      authState.kind === "authenticated" &&
      isNonNullable(likeEndpoint) &&
      isNonNullable(unlikeEndpoint)
    ) {
      const isSuccessfullRequest = await requestChange(
        isLikedState ? unlikeEndpoint : likeEndpoint,
        isLikedState,
      );
      if (isSuccessfullRequest) {
        setIsLikedState(!isLikedState);

        showNotification?.({
          type: SeverityType.success,
          message: isLikedState
            ? unlikeSnackbar?.message
            : likeSnackbar?.message,
        });
      } else {
        showNotification?.({
          type: SeverityType.error,
          message: t`GENERIC_ERROR_MESSAGE`,
        });
      }
    }
  };

  return { clickHandler: changeFollowStatus, isLikedState: isLikedState };
};
