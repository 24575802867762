import type { FC } from "react";
import type { EmptyStateComponent } from "@motain/xpa-proto-files-web/lib/types/empty_state";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { isNonNullable } from "@/types/isNonNullable";
import { ButtonLink } from "@/components/button/ButtonLink";
import styles from "./EmptyState.module.scss";
import classNames from "classnames";

const EmptyState: FC<EmptyStateComponent> = (props) => {
  const { imageObject, cta, title, message } = props;

  return (
    <section className={styles.container}>
      <p className={"title-6-bold"}>{title}</p>

      <p className={classNames("title-7-regular", styles.text)}>{message}</p>

      {isNonNullable(cta) && (
        <ButtonLink variant={"ghost"} href={cta.urlPath}>
          {cta.label}
        </ButtonLink>
      )}

      {isNonNullable(imageObject) && (
        <ImageWithFallback
          width={320}
          height={320}
          alt={imageObject.alt}
          src={imageObject.path}
          darkModeSrc={imageObject.darkModePath}
          className={styles.image}
        />
      )}
    </section>
  );
};

export { EmptyState };
